<template>
  <section id="yandex-direct-code" />
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    const { code } = this.$route.query
    this.$http.get(`/yandex-direct/token?code=${code}`)
      .then(response => {
        const tokens = response.data
        this.$router.push({
          name: 'yandex-direct',
          params: { connectionId: this.$route.query.state, directTokens: tokens },
        })
      })
  },
  created() {
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
